import { Chip } from "@mui/material";
import { CellContext, flexRender } from "@tanstack/react-table";
import MeasureIdChip from "../../../components/MeasureIdChip";
import { TemplateType } from "../../../lib/excel";
import { ValidatedExcelDataCell } from "../../../lib/excel-validate";
import { ImportTableData } from "./ImportTableWithHeader";

type ImportTableIdCellProps = CellContext<ImportTableData, ValidatedExcelDataCell> & { templateType: TemplateType };

const ImportTableIdCell = ({ getValue, templateType, cell }: ImportTableIdCellProps) => {
    const value = getValue();
    const hasError = value != undefined && value.errorMessage !== "";
    if (hasError) {
        return <Chip label="!" color="error" sx={{ height: (theme) => theme.spacing(2), minWidth: (theme) => theme.spacing(4) }} />;
    }

    const isMeasure = templateType !== TemplateType.Opps;
    return value?.value != null && value?.value !== "" && isMeasure
        ? flexRender(<MeasureIdChip measureId={Number(value.value)} />, cell.getContext())
        : flexRender(
              <Chip
                  label={value?.value ? Number(value.value) : ""}
                  color="default"
                  sx={{ height: (theme) => theme.spacing(2), minWidth: (theme) => theme.spacing(4) }}
              />,
              cell.getContext(),
          );
};

export default ImportTableIdCell;
