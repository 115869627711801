import { TypographyProps } from "@mui/material";
import { CellContext, flexRender } from "@tanstack/react-table";

import TableOverflowWrapper from "../../../components/table/CellRenderer/TableOverflowWrapper";
import { ValidatedExcelDataCell } from "../../../lib/excel-validate";

export interface TableTextCellProps<D extends object = Record<string, unknown>>
    extends CellContext<D, ValidatedExcelDataCell>,
        Pick<TypographyProps, "align" | "variant" | "className"> {
    title?: string | null;
    children?: any;
}

const ImportTableTextCell = <D extends object = Record<string, string>>({
    title,
    children,
    align,
    variant,
    className,
    cell,
}: TableTextCellProps<D>) => {
    const content = cell.getValue() ?? (children as string);

    return content != null ? (
        <TableOverflowWrapper title={title} align={align} className={className}>
            {flexRender(content.value, cell.getContext())}
        </TableOverflowWrapper>
    ) : null;
};

export default ImportTableTextCell;
