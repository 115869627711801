import { CreateUpdateMeasureEffectMetaDtoV1, CreateUpdateMeasureFieldMetaDtoV1 } from "api-shared";
import { IdeaEstimates } from "../view/ideas/hooks";
import {
    IdFieldDisplayName,
    IdFieldInternalName,
    ParsedExcelData,
    ParsedExcelDataRow,
    TitleFieldDisplayName,
    TitleFieldInternalName,
    mapTranslatedValuesToListWithInternalName,
} from "./excel";

/*
There are countless ways for our customers to mess up their Excel files.
We try to check for as many of them as possible and give them meaningful error messages.
Over time it is very likely that new checks will be added due to customers finding new ways to break things.
Make sure that the new checks are added to all relevant functions and tests!
*/

// Error contants
export const ValueNotInAllowedValues = "Value not in allowed values.";
export const CharactersNotAllowedInNumber = "Invalid value for number field.";
export const ValueMissingTitle = "Missing Title.";

// Map of displayId to apiId
export type DisplayIdMapping = Map<number, number>;

export type ValidatedExcelDataCell = { errorMessage: string; value: string; fieldName: string };
export type ValidatedExcelData = {
    rowNumber: number;
    apiId: number | undefined; // technical id of the api object (id is used for displayId in the Excel)
    fields: Record<
        string, // technical name of the field => .field
        ValidatedExcelDataCell
    >;
};

// Check field definitions and values for measure fields
export function validateMeasureFieldsData(
    parsedRowData: ParsedExcelData,
    createMeta: CreateUpdateMeasureFieldMetaDtoV1,
    mapping: DisplayIdMapping,
): ValidatedExcelData[] {
    const data: ValidatedExcelData[] = mapParsedToValidatedFields(parsedRowData);
    mapDisplayIdToApiId(data, mapping);
    validateIdAndTitle(data);
    validationFields(data, createMeta);
    return data;
}

// Check effect definitions and values for measure effects
export function validateMeasureEffectData(
    parsedRowData: ParsedExcelData,
    createMeta: CreateUpdateMeasureEffectMetaDtoV1,
    mapping: DisplayIdMapping,
): ValidatedExcelData[] {
    const data: ValidatedExcelData[] = mapParsedToValidatedFields(parsedRowData);
    mapDisplayIdToApiId(data, mapping);
    validateId(data);
    validateEffects(data, createMeta);
    return data;
}

// Check opps basic fields and values
export function validateOppsData(
    parsedRowData: ParsedExcelData,
    mapping: DisplayIdMapping,
    estimates: IdeaEstimates,
): ValidatedExcelData[] {
    const data: ValidatedExcelData[] = mapParsedToValidatedFields(parsedRowData);
    mapDisplayIdToApiId(data, mapping);
    validateIdMandatoryTitle(data);
    validationOppsFields(data, estimates);
    return data;
}

// Convert the cells to row objects with field names as keys
export function mapParsedToValidatedFields(parsedRowData: ParsedExcelData) {
    const data: ValidatedExcelData[] = [];

    // Create a lookup table for field names and fields
    const fieldLookup = parsedRowData.config.fieldDisplayNames.reduce((result: ParsedExcelDataRow, current, index) => {
        result[current] = parsedRowData.config.fieldInternalFields[index];
        return result;
    }, {});

    parsedRowData.data.forEach((rowData, index) => {
        const row: ValidatedExcelData = { rowNumber: index, fields: {}, apiId: undefined };
        Object.entries(rowData).forEach(([key, value]) => {
            row.fields[fieldLookup[key] ?? `X-${key}` /* Missing key indicator - shouldn't happen */] = {
                errorMessage: "",
                value: value,
                fieldName: key,
            };
        });
        data.push(row);
    });

    return data;
}

// Map opps, measure IDs to API IDs
export function mapDisplayIdToApiId(data: ValidatedExcelData[], mapping: DisplayIdMapping) {
    data.forEach((row) => {
        if (IdFieldInternalName in row.fields && row.fields[IdFieldInternalName].value != "") {
            const displayId = +row.fields[IdFieldInternalName].value;
            const apiId = mapping.get(displayId);
            row.apiId = apiId;
        }
    });
}

// Check that all required fields are present
export function validateIdAndTitle(validatedRowData: ValidatedExcelData[]) {
    validatedRowData.forEach(({ fields, apiId }) => {
        const hasIdField = IdFieldInternalName in fields;
        const hasTitleField = TitleFieldInternalName in fields;

        if (!hasIdField && !hasTitleField) {
            fields[IdFieldInternalName] = {
                errorMessage: "Missing ID and Title.",
                value: "",
                fieldName: IdFieldDisplayName,
            };
            return;
        }

        if (!hasIdField && hasTitleField && fields[TitleFieldInternalName].value == "") {
            fields[TitleFieldInternalName] = {
                errorMessage: ValueMissingTitle,
                value: "",
                fieldName: TitleFieldDisplayName,
            };
            return;
        }

        if (hasIdField && !hasTitleField && fields[IdFieldInternalName].value == "") {
            fields[TitleFieldInternalName] = {
                errorMessage: "Title must be set if ID is empty.",
                value: "",
                fieldName: IdFieldDisplayName,
            };
            return;
        }

        if (hasIdField && hasTitleField && fields[IdFieldInternalName].value == "" && fields[TitleFieldInternalName].value == "") {
            fields[TitleFieldInternalName].errorMessage = "Title must be set if ID is empty.";
            return;
        }

        if (hasIdField && isNaN(parseInt(fields[IdFieldInternalName].value ?? ""))) {
            fields[IdFieldInternalName].errorMessage = "ID must be a number.";
            return;
        }

        if (hasIdField && apiId === undefined) {
            fields[IdFieldInternalName].errorMessage = "ID couldn't be found.";
        }
    });
}

export function validateIdMandatoryTitle(validatedRowData: ValidatedExcelData[]) {
    validateIdAndTitle(validatedRowData);

    validatedRowData.forEach(({ fields }) => {
        if (!(TitleFieldInternalName in fields)) {
            fields[TitleFieldInternalName] = {
                errorMessage: ValueMissingTitle,
                value: "",
                fieldName: IdFieldDisplayName,
            };
            return;
        }
        if (fields[TitleFieldInternalName].value == "") {
            fields[TitleFieldInternalName].errorMessage = ValueMissingTitle;
        }
    });
}

// Check that required ID field is present
export function validateId(validatedRowData: ValidatedExcelData[]) {
    validatedRowData.forEach(({ fields, apiId }) => {
        if (!(IdFieldInternalName in fields)) {
            fields[IdFieldInternalName] = {
                errorMessage: "Missing ID.",
                value: "",
                fieldName: IdFieldDisplayName,
            };
            return;
        }

        if (fields[IdFieldInternalName].value == "") {
            fields[IdFieldInternalName].errorMessage = "ID must be set.";
            return;
        }

        if (isNaN(parseInt(fields[IdFieldInternalName].value ?? ""))) {
            fields[IdFieldInternalName].errorMessage = "ID must be a number.";
            return;
        }

        if (apiId === undefined) {
            fields[IdFieldInternalName].errorMessage = "ID couldn't be found.";
        }
    });
}

// Validate that all fields are present and that lookup fields are valid
export function validationFields(validatedRowData: ValidatedExcelData[], createMeta: CreateUpdateMeasureFieldMetaDtoV1) {
    validatedRowData.forEach((row) => {
        Object.entries(row.fields).forEach(([key, cellValue]) => {
            if (key === IdFieldInternalName || key == TitleFieldInternalName) {
                return;
            }

            if (!(key in createMeta.fields)) {
                row.fields[key].errorMessage = "Field name not found in measure config.";
                return;
            }

            const fieldMeta = createMeta.fields[key];

            if (isSimpleText(fieldMeta, cellValue)) {
                return;
            }

            if (fieldMeta.type == "number") {
                if (cellValue.value != "" && isNaN(parseFloat(cellValue.value))) {
                    row.fields[key].errorMessage = CharactersNotAllowedInNumber;
                }
                return;
            }

            // Check for user provided values altough not allowed values
            if (fieldMeta.allowedValues == undefined && cellValue.value != "") {
                row.fields[key].errorMessage = ValueNotInAllowedValues;
                return;
            }

            if (hasNoValues(fieldMeta, cellValue)) {
                return;
            }

            // Handle extended lookup values
            if (isCreatableLookupField(fieldMeta, cellValue)) {
                return;
            }

            const allowedValues = fieldMeta.allowedValues?.flatMap(mapTranslatedValuesToListWithInternalName).filter((s) => s != "");
            if (!allowedValues?.includes(cellValue.value)) {
                row.fields[key].errorMessage = ValueNotInAllowedValues;
            }
        });
    });
}

// Check the required fields are present
export function validationOppsFields(validatedRowData: ValidatedExcelData[], estimates: IdeaEstimates) {
    const estimationFields = ["potentialEstimate", "timeEstimate", "effortEstimate"] as const;
    validatedRowData.forEach(({ fields }) => {
        estimationFields.forEach((fieldName) => {
            if (fieldName in fields) {
                const fieldValues = estimates[`${fieldName}s`].map((s) => s.label.toString());
                if (!fieldValues.includes(fields[fieldName].value)) {
                    fields[fieldName].errorMessage = ValueNotInAllowedValues;
                }
            }
        });
    });
}

// Check the required fields are present and the effects are numeric
export function validateEffects(validatedRowData: ValidatedExcelData[], createMeta: CreateUpdateMeasureEffectMetaDtoV1) {
    const checkEffect = /EC-\d{2}-\d{4}-[a-z]+/;
    const generations = createMeta.generations.map((s) => s.name.toString());

    validatedRowData.forEach(({ fields }) => {
        validateGenerations(fields, generations);
        validateEffectCategories(createMeta, fields);
        validateEffectsTypeAndFormats(fields, checkEffect);
    });
}

function validateGenerations(fields: Record<string, ValidatedExcelDataCell>, generations: string[]) {
    if (!("generation" in fields)) {
        fields.generation = {
            errorMessage: "Missing Generation.",
            value: "",
            fieldName: "Generation",
        };
    }

    if (fields.generation.value == "" || !generations.includes(fields.generation.value)) {
        fields.generation.errorMessage = ValueNotInAllowedValues;
    }
}

function validateEffectCategories(createMeta: CreateUpdateMeasureEffectMetaDtoV1, fields: Record<string, ValidatedExcelDataCell>) {
    Object.entries(createMeta.effectCategories).forEach(([key]) => {
        if (!(key in fields)) {
            if (key === "currency") {
                // currency is optional in import to make multi currency in inport non-breaking
                return;
            }

            fields[key] = {
                errorMessage: "Missing Effect Category.",
                value: "",
                fieldName: key,
            };
            return;
        }

        const ecValue = fields[key].value;
        if (ecValue == "") {
            fields[key].errorMessage = "Effect Category must be set.";
            return;
        }

        const allowedValues = createMeta.effectCategories[key].values.flatMap((s) => [s.name, ...Object.values(s.nameTranslations ?? {})]);
        if (!allowedValues.includes(ecValue)) {
            fields[key].errorMessage = ValueNotInAllowedValues;
        }
    });
}

function validateEffectsTypeAndFormats(fields: Record<string, ValidatedExcelDataCell>, checkEffect: RegExp) {
    Object.keys(fields)
        .filter((key) => key.startsWith("EC-"))
        .forEach((key) => {
            if (!checkEffect.test(key)) {
                fields[key].errorMessage = "Invalid effect type.";
                return;
            }

            if (fields[key].value != "" && fields[key].value.toLowerCase() != "x" && isNaN(parseFloat(fields[key].value ?? ""))) {
                fields[key].errorMessage = "Value must be a number or 'x' to delete the value.";
            }
        });
}

function isCreatableLookupField(fieldMeta: any, cellValue: any): boolean {
    return fieldMeta.type == "lookup" && fieldMeta.isCreatable && cellValue.value != "";
}

function hasNoValues(fieldMeta: any, cellValue: any): boolean {
    return fieldMeta.allowedValues == undefined || fieldMeta.allowedValues.length == 0 || cellValue.value == "";
}

function isSimpleText(fieldMeta: any, cellValue: any): boolean {
    return fieldMeta.type != "lookup" && fieldMeta.type != "lookuplist" && fieldMeta.type != "number";
}
