import { useTranslation } from "react-i18next";

import { Grid, styled, tableCellClasses } from "@mui/material";

import { translationKeys } from "../../../translations/main-translations";
import ImportTableBaseCellWrapper from "./ImportTableBaseCellWrapper";

import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { useMemo } from "react";
import TableHeaderCell from "../../../components/table/CellRenderer/TableHeaderCell";
import UncontrolledTable from "../../../components/table/UncontrolledTable/UncontrolledTable";
import { IdFieldInternalName, ParsedExcelData, TemplateType, TitleFieldInternalName } from "../../../lib/excel";
import { ValidatedExcelData, ValidatedExcelDataCell } from "../../../lib/excel-validate";
import ImportTableHeader from "./ImportTableHeader";
import ImportTableIdCell from "./ImportTableIdCell";
import ImportTableLinkCell from "./ImportTableLinkCell";
import ImportTableStatusCell from "./ImportTableStatusCell";
import ImportTableTextCell from "./ImportTableTextCell";
import { ImportTableStatus } from "./table_utils";

export const getValidatedDataWithStatus = (validatedExcelData: ValidatedExcelData[]) =>
    validatedExcelData.map((data) => ({
        ...data,
        status: { value: ImportTableStatus.NotStarted, errorMessage: "" },
    }));

interface IImportTableWithHeaderProps {
    validatedData: ImportTableData[];
    parsedExcelData: ParsedExcelData;
    onImportClick: () => void;
    onClearExcelData: () => void;
    onImportCancelClick: () => void;
    onImportResumeClick: () => void;
}

export type ImportTableStatusAndErrorMessage = {
    value: ImportTableStatus;
    errorMessage: string;
};

export type ImportTableData = ValidatedExcelData & {
    status: ImportTableStatusAndErrorMessage;
};

const RootGrid = styled(Grid)({ height: "100%" });
const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
});

const FullHeightCellUncontrolledTable = styled(UncontrolledTable<ImportTableData>)(({ theme }) => ({
    // padding needs to be removed, because the error state should fill the whole cell
    // the height needs to have a fixed value for the child component to be able to scale to 100% height
    // setting the height to 1px!
    [`& .${tableCellClasses.body}`]: {
        padding: 0,
        height: 1,
    },
    ["& #no-data-table-cell"]: {
        padding: theme.spacing(),
    },
}));

const columnHelper = createColumnHelper<ImportTableData>();

const renderStatus = (props: CellContext<ImportTableData, ImportTableStatusAndErrorMessage>) => (
    <ImportTableBaseCellWrapper
        errorMessage={props.getValue()?.errorMessage ?? ""}
        // do not feed in status prop because status cell should not be affected by opacity
    >
        <ImportTableStatusCell {...props} apiId={props.row.original.apiId} />
    </ImportTableBaseCellWrapper>
);

const renderIdField = (props: CellContext<ImportTableData, ValidatedExcelDataCell>, templateType: TemplateType) => (
    <ImportTableBaseCellWrapper errorMessage={props.getValue()?.errorMessage ?? ""} status={props.row.original.status.value}>
        <ImportTableIdCell {...props} templateType={templateType} />
    </ImportTableBaseCellWrapper>
);

const renderInternalNameField = (props: CellContext<ImportTableData, ValidatedExcelDataCell>, templateType: TemplateType) => (
    <ImportTableBaseCellWrapper errorMessage={props.getValue()?.errorMessage ?? ""} status={props.row.original.status.value}>
        <ImportTableLinkCell {...props} apiId={props.row.original.apiId} templateType={templateType} />
    </ImportTableBaseCellWrapper>
);

const renderField = (props: CellContext<ImportTableData, ValidatedExcelDataCell>) => (
    <ImportTableBaseCellWrapper errorMessage={props.getValue()?.errorMessage ?? ""} status={props.row.original.status.value}>
        <ImportTableTextCell {...props} />
    </ImportTableBaseCellWrapper>
);

const ImportTableWithHeader = ({
    validatedData,
    parsedExcelData,
    onImportClick,
    onClearExcelData,
    onImportCancelClick,
    onImportResumeClick,
}: IImportTableWithHeaderProps) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            columnHelper.accessor((data) => data.status, {
                header: TableHeaderCell,
                meta: { label: "Status" },
                id: "status",
                cell: renderStatus,
                size: 85,
                maxSize: 200,
            }),
            ...parsedExcelData.config.fieldInternalFields.map((field, index) => {
                if (field === IdFieldInternalName) {
                    return columnHelper.accessor((data) => data.fields[field], {
                        header: TableHeaderCell,
                        id: index.toString(),
                        cell: (props) => renderIdField(props, parsedExcelData.config.templateType),
                        meta: { label: t(translationKeys.VDLANG_ID) },
                        size: 60,
                    });
                }

                if (field === TitleFieldInternalName) {
                    return columnHelper.accessor((data) => data.fields[field], {
                        header: TableHeaderCell,
                        id: index.toString(),
                        cell: (props) => renderInternalNameField(props, parsedExcelData.config.templateType),
                        meta: { label: parsedExcelData.config.fieldDisplayNames[index] },
                        size: 320,
                    });
                }

                return columnHelper.accessor((data) => data.fields[field], {
                    header: TableHeaderCell,
                    id: index.toString(),
                    cell: renderField,
                    meta: { label: parsedExcelData.config.fieldDisplayNames[index] },
                });
            }),
        ],
        [parsedExcelData.config.fieldDisplayNames, parsedExcelData.config.fieldInternalFields, parsedExcelData.config.templateType, t],
    );

    return (
        <RootGrid container direction="column" wrap="nowrap">
            <ImportTableHeader
                onClearExcelData={onClearExcelData}
                onImportCancelClick={onImportCancelClick}
                onImportClick={onImportClick}
                onImportResumeClick={onImportResumeClick}
                validatedData={validatedData}
            />
            <Grow item>
                <FullHeightCellUncontrolledTable
                    fullHeight
                    columns={columns}
                    data={validatedData}
                    noDataText={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_NO_DATA)}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grow>
        </RootGrid>
    );
};

export default ImportTableWithHeader;
