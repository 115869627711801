// regenerator-runtime/runtime must be the first import or at least before exceljs
// prettier-ignore-start
import "regenerator-runtime/runtime"; // Required for ExcelJS to work with bare.min.js version (which in turn is required to prevent unsafe-eval)
// prettier-ignore-end
import { TranslatedString } from "api-shared";

export enum TemplateType {
    MeasureFields = "measureFields",
    MeasureEffects = "measureEffects",
    Opps = "opps",
}

export const ConfigTemplateTypeKey = "templateType";
export const ConfigMeasureConfigIdKey = "measureConfigId";
export const ConfigFieldNamesKey = "fieldDisplayNames";
export const ConfigFieldFieldsKey = "fieldInternalFields";

// Label displayed to the user in the preview table and in the excel sheet
export const IdFieldDisplayName = "ID";
export const TitleFieldDisplayName = "Title";
export const FieldResponsiblePersonName = "Responsible person";

// Internal names for accessing those fields using row.fields[...]
export const IdFieldInternalName = "id";
export const TitleFieldInternalName = "title";
export const AssignedToInternalName = "assignedTo";

// Type definitions
export type ExcelImportConfig = {
    templateType: TemplateType;
    measureConfigId: number;
    fieldDisplayNames: string[];
    fieldInternalFields: string[];
};

export type ParsedExcelDataRow = Record<string, string>;
export type ParsedExcelData = { config: ExcelImportConfig; data: ParsedExcelDataRow[] };

export const mapTranslatedValuesToList = (allowedValue: string | number | TranslatedString) => {
    if (typeof allowedValue === "string") {
        return allowedValue;
    }
    if (typeof allowedValue === "number") {
        return allowedValue.toString();
    }
    if (allowedValue.nameTranslations !== undefined) {
        return [allowedValue.nameTranslations.en, allowedValue.nameTranslations.de];
    }
    return allowedValue.name;
};

export const mapTranslatedValuesToListWithInternalName = (allowedValue: string | number | TranslatedString) => {
    const allowed = mapTranslatedValuesToList(allowedValue);

    // Allow internal name for values in validation to support old templates that still contain internal names as values
    if (Array.isArray(allowed) && typeof allowedValue !== "string" && typeof allowedValue !== "number") {
        allowed.push(allowedValue.name);
    }

    return allowed;
};
